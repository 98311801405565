import React,{useRef} from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import {useSelector} from "react-redux";
import {
    cartResumeSelector,
    cartSelector,
    validateCoupon,
    changeCurrency,
    currencyChange,
    addItem
} from "../../store/cart/cart";
import {Dinero, Paypal,OpenPay,Advertencia} from "../../assets/images/index";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import Avatar from '@mui/material/Avatar';
import FormControl from "@mui/material/FormControl";
import {unwrapResult} from '@reduxjs/toolkit';
import {PaymentButton} from '../paypalButtons/paypalButtons'
import {useAppDispatch} from "../../store/store";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

import {changePaymentMethod} from "../../store/cart/cart"
import {Announcement} from "../announcement/announcement"
interface IPayment{
    isVisible:boolean,
    isVisibleCoupon:boolean,
    isVisibleFinalBook:boolean
    promotion:boolean,
    coupon:string,
}
export const PurchaseDetail = ({isVisible,isVisibleFinalBook,promotion,coupon,isVisibleCoupon}:IPayment) => {
    const dispatch = useAppDispatch();
    const inputRef = useRef('');
    const CartSelectorResume = useSelector(cartResumeSelector);
    const CartSelector = useSelector(cartSelector);
    const { t } = useTranslation();
    let {
        resume_amount,
        resume_adult,
        resume_children,
        resume_currency,
        resume_discount,
        resume_subtotal,
        resume_addon,
        resume_promotion,
        resume_coupon,
        resume_coupon_name,
        resume_cart_contain_snorkel
    } = CartSelectorResume;
    let {
        cookie,
        payment_method,
        id_cart
    } = CartSelector;


    const currenSelect=(currency:string)=>{
        dispatch(currencyChange({currency:currency??''})).then(unwrapResult).then((result: any) => {
            dispatch(openNotifier({variant: "success", open: true, message: `change currency successfully`}))
        }).catch((result: AxiosError) => {
            // @ts-ignore
            dispatch(openNotifier({variant: "error", open: true, message: `an error occurred with the change currency,try with a valid one`}))
        })
    }

    return (
        <>
            {
                isVisibleFinalBook &&<>
                    <Announcement isVisible={false}></Announcement>
                    {
                        resume_cart_contain_snorkel ?   <div className="contInfoSNK">
                                <div className="container gridNotice">
                                    <img src={Advertencia} alt="imagen Noticia"/>
                                    <p>{t('snorkel_1_january'??'',{ ns: namespaces.purchase_details})}</p>
                                </div>
                            </div>:
                            <></>
                    }
                    <Grid md={12} sm={12} xs={12} display="flex" justifyContent="left" alignItems="flex-end">
                        <div className="AshleySemBold txt-25">{t('purchase_details'??'',{ ns: namespaces.purchase_details})}</div>
                    </Grid>

                    {
                        (promotion && (resume_coupon_name==='') && isVisibleCoupon)  &&  <Grid md={12} xs={16}>
                            <div className="contPromoInput">
                                <TextField
                                    id="standard-basic"
                                    inputRef={inputRef}
                                    label={t('enter_code'??'',{ ns: namespaces.purchase_details})} variant="standard" />
                                <Button variant="outlined"
                                        onClick={(event)=>{
                                            // @ts-ignore
                                            dispatch(validateCoupon({coupon:inputRef.current.value,cookie:cookie}))
                                        }}
                                        className="btnCupon"
                                >{t('apply_code'??'',{ ns: namespaces.purchase_details})} <SellOutlinedIcon/></Button>
                            </div>
                        </Grid>
                    }

                    <Grid md={6} sm={6} xs={6} display="flex" alignSelf="self-end" justifySelf="left" className="borderB">
                        {
                            resume_cart_contain_snorkel ?   <div>{t('adults'??'',{ ns: namespaces.payment_process})}:</div>:
                                <div>{t('adults'??'',{ ns: namespaces.check_your_cart})}:</div>
                        }


                    </Grid>
                    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                        <div>{resume_adult}</div>
                    </Grid>

                    <Grid md={6} sm={6} xs={6} display="flex" alignSelf="self-end" justifySelf="left" className="borderB">
                        {
                            resume_cart_contain_snorkel ?   <div>{t('children'??'',{ ns: namespaces.payment_process})}:</div>:
                                <div>{t('children'??'',{ ns: namespaces.check_your_cart})}:</div>
                        }
                    </Grid>
                    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                        <div>{resume_children}</div>
                    </Grid>
                    <Grid md={6} sm={6} xs={6} display="flex" alignSelf="self-end" justifySelf="left" className="borderB">
                        <div>Add-ons:</div>
                    </Grid>
                    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                        <div>{resume_addon}</div>
                    </Grid>
                    {
                        promotion && <Grid md={6} sm={6} xs={6} display="flex" alignSelf="self-end" justifySelf="left" className="borderB">
                            <div>Sub Total:</div>
                        </Grid>
                    }
                    {
                        promotion &&      <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                            <div>${resume_subtotal} {resume_currency}</div>
                        </Grid>
                    }
                    {
                        promotion &&    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="left" alignItems="center" className="borderB">
                            <div> {t('total_discount'??'',{ ns: namespaces.purchase_details})}:</div>
                        </Grid>
                    }

                    {
                        promotion &&  <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                            <div id={'id_total_discount'}>${resume_discount} {resume_currency}</div>
                        </Grid>
                    }

                    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="left" alignItems="center" className="borderB">
                        <div >Total:</div>
                    </Grid>
                    <Grid md={6} sm={6} xs={6} display="flex" justifyContent="right" alignItems="center" className="borderB">
                        <div>${resume_amount} {resume_currency}</div>
                    </Grid>
                </>
            }


            {/*{*/}
            {/*    isVisible &&            <Grid md={12} xs={16} alignSelf="self-end" justifySelf="left">*/}
            {/*        <Typography variant="subtitle1" gutterBottom className="AshleySemBold" display="flex" >*/}
            {/*            {t('pay_with'??'',{ ns: namespaces.purchase_details})} <img src={Dinero} alt="icon metodo de pago" className="img-dinero"/>*/}
            {/*        </Typography>*/}
            {/*        <List dense sx={{ width: '100%' }} className="inputwidth">*/}
            {/*            <ListItem*/}
            {/*                key={'usd-payment'}*/}
            {/*                disablePadding*/}
            {/*            >*/}
            {/*                <ListItemButton className="listPayment">*/}
            {/*                    <ListItemAvatar>*/}
            {/*                        <FormControl>*/}
            {/*                            <Radio*/}
            {/*                                edge="start"*/}
            {/*                                onChange={(event, checked)=>{*/}
            {/*                                    currenSelect(event.currentTarget.value)*/}
            {/*                                }}*/}
            {/*                                value={'USD'}*/}
            {/*                                checked={resume_currency==='USD'}*/}
            {/*                                disableRipple*/}
            {/*                            />*/}
            {/*                        </FormControl>*/}

            {/*                    </ListItemAvatar>*/}
            {/*                    <ListItemText id={'Avatar1'} primary={`USD`} />*/}
            {/*                </ListItemButton>*/}
            {/*            </ListItem>*/}
            {/*        </List>*/}
            {/*        <List dense sx={{ width: '100%' }} className="inputwidth">*/}
            {/*            <ListItem*/}
            {/*                key={'usd-payment'}*/}
            {/*                disablePadding*/}
            {/*            >*/}
            {/*                <ListItemButton className="listPayment">*/}
            {/*                    <ListItemAvatar>*/}
            {/*                        <FormControl>*/}
            {/*                            <Radio*/}
            {/*                                edge="start"*/}
            {/*                                onChange={(event, checked)=>{*/}
            {/*                                    currenSelect(event.currentTarget.value)*/}
            {/*                                }}*/}
            {/*                                value={'MXN'}*/}
            {/*                                checked={resume_currency==='MXN'}*/}
            {/*                                disableRipple*/}
            {/*                            />*/}
            {/*                        </FormControl>*/}

            {/*                    </ListItemAvatar>*/}
            {/*                    <ListItemText id={'Avatar1'} primary={`MXN`} />*/}
            {/*                </ListItemButton>*/}
            {/*            </ListItem>*/}
            {/*        </List>*/}
            {/*    </Grid>*/}
            {/*}*/}
            {
                isVisible && <>
                    <Grid md={12} xs={16} alignSelf="self-end" justifySelf="left">
                        <Typography variant="subtitle1" gutterBottom className="AshleySemBold" display="flex" >
                            {t('payment_methods'??'',{ ns: namespaces.purchase_details})} <img src={Dinero} alt="icon metodo de pago" className="img-dinero"/>
                        </Typography>

                        {/* <PaymentButton></PaymentButton> */}

                         <List dense sx={{ width: '100%' }} className="inputwidth">

                       <ListItem
                             key={1}
                             secondaryAction={
                                    <Avatar
                                    className="avatar"
                                    alt={`Avatar1`}
                                       src={Paypal}
                                  />
                              }
                              disablePadding
                          >
                                <ListItemButton className="listPayment">
                                  <ListItemAvatar>
                                      <FormControl>
                                         <Radio
                                             edge="start"
                                                onChange={(event, checked)=>{
                                                   dispatch(changePaymentMethod(event.currentTarget.value))

                                               }}
                                               value={'paypal'}
                                               checked={payment_method==='paypal'}
                                                disableRipple
                                            />
                                        </FormControl>

                                   </ListItemAvatar>
                                   <ListItemText id={'Avatar1'} primary={t('card'??'',{ ns: namespaces.purchase_details})} />
                               </ListItemButton>
                            </ListItem>

                        {
                        payment_method==='paypal' &&   <PaymentButton></PaymentButton>
                       }


                             {/*<ListItem*/}
                             {/*    key={2}*/}
                             {/*    secondaryAction={*/}
                             {/*        <Avatar*/}
                             {/*            className="avatar"*/}
                             {/*            alt={`Avatar15`}*/}
                             {/*            src={OpenPay}*/}
                             {/*        />*/}
                             {/*    }*/}
                             {/*    disablePadding*/}
                             {/*>*/}
                             {/*    <ListItemButton className="listPayment">*/}
                             {/*        <ListItemAvatar>*/}
                             {/*            <FormControl>*/}
                             {/*                <Radio*/}
                             {/*                    edge="start"*/}
                             {/*                    disabled={true}*/}
                             {/*                    onChange={(event, checked)=>{*/}

                             {/*                        dispatch(changePaymentMethod(event.currentTarget.value))*/}
                             {/*                    }}*/}
                             {/*                    value={'openpay'}*/}
                             {/*                    checked={payment_method==='openpay'}*/}
                             {/*                    disableRipple*/}
                             {/*                />*/}
                             {/*            </FormControl>*/}
                             {/*        </ListItemAvatar>*/}
                             {/*        <ListItemText id={'Avatar15'} primary={`Open Pay`} />*/}
                             {/*    </ListItemButton>*/}
                             {/*</ListItem>*/}
                             {/*{*/}
                             {/*    payment_method==='openpay' &&     <FormOpenPay></FormOpenPay>*/}
                             {/*}*/}
                        </List>
                    </Grid>
                </>
            }

        </>
    );
}
