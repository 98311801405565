import { namespaces } from "./i18n.constants";

export const en = {
    [namespaces.header]: {
        lang: "ESP",
        shopping_cart:"Shopping Cart",
        add:"Add",
        acepp:"Accept",
        cancel:"Cancel",
        question_1:"Are you sure you want to delete it",
        question_2:"from your shopping cart",
        attention:"Attention",
        something_wrong:"Something has gone wrong...",
        date_choose:"The date you chose is not available at the moment, please choose a different date.",
    },
    [namespaces.steps]: {
        "Check your cart": "Check your cart",
        "Customer information":"Customer information",
        "Add-ons for your fun":"Add-ons for your fun",
        "Payment process":"Payment Process",
        "Confirmation":"Confirmation",
    },
    [namespaces.check_your_cart]: {
        date_of_reservation: "Date of Reservation",
        adults: "Adults",
        children: "Children",
        transportation: "Transportation",
        total: "Total",

    },
    [namespaces.client_information]: {
        please_enter_your_information_correctly: "Please enter your information correctly",
        im_coming_in: "I'm coming in",
        hotel: "Hotel",
        cruise_line: "Cruise Line",
        select_hotel_cruise: "Select Hotel / Cruise",
        choose: "Choose",
        name: "Name",
        last_name: "Last Name",
        email: "E-mail",
        phone_number: "Phone number",
        select_country:"Select your Country",
        state_province: "State/Province",
        city: "City",
        country: "Country",
        confirm: "I have read and agree to the terms and conditions (shopping cart).",
    },
    [namespaces.mistakes]:{
        select_an_arrival_method: "select an arrival method",
        Select_hotel_cruise_line: "You must Select Hotel / Cruise Line",
        you_must_enter_a_name: "You must enter a name",
        you_must_enter_your_last_name: "You must enter your last name",
        you_must_enter_a_valid_email: "You must enter a valid email",
        you_must_enter_a_phone_number: "You must enter a phone number",
        you_must_enter_a_state_or_province: "You must enter a state or province",
        you_must_choose_a_country: "You must choose a country",
        you_must_choose_a_city: "You must choose a city",
        checked_confirm: "You must mark in the circle to proceed.",
    },
    [namespaces.add_ons]:{
        recommended_products_to_increase_your_fun:"Recommended products to increase your fun",
        adults:"Adults",
        total:"total",
        add:"add",
        recommended_products_to_increase_your_fun_snorkel:"Enhance your experience with our extra activities, available to book once you arrive at the club"
    },
    [namespaces.payment_process]:{
    details_of_the_reservation:"Details of the Reservation",
    date_of_reservation:"Date of Reservation",
    adults:"Adults",
    children:"Children (age: 6-11)",
    transportation:"Transportation",
    },
    [namespaces.purchase_details]:{
        apply_code:"Apply code",
        enter_code:"Enter your discount code",
        purchase_details:"Purchase Details",
        subtotal:"Subtotal",
        total_discount:"Total Discount",
        total:"Total",
        pay_with:"Pay with",
        payment_methods:"Payment methods",
        continue:"Continue",
        card:"Paypal",
        snorkel_1_january:"By mandate of the federal government, we inform you that starting January 1, 2025, a conservation tax of $11 USD for the marine park will be applied. This tax is not included in the cost and must be paid by the customer in cash on the day of the tour."
    },
    [namespaces.final_book]:{
        booking_number:"BOOKING NUMBER",
        country_origin:"COUNTRY OF ORIGIN",
        date_of_purchase:"DATE OF PURCHASE",
        city:"CITY",
        package_price:"PACKAGE PRICE (PAID)",
        name:"NAME OF PURCHASING GUEST",
        date_arrival:"DATE OF ARRIVAL",
        adult:"Adult",
        child:"Child",
        cruise_line:"CRUISE LINE/ HOTEL",
        date_of_arrival:"Date of arrival",
        important_facts:"IMPORTANT FACTS FOR YOUR DAY IN PLAYA MIA",
        important_1:"Playa Mia is open from Monday to Saturday, from 9 am to 6 pm LOCAL TIME",
        important_2:"Please print this e-ticket and bring it with you, you will have to show it at the park's entrance.",
        important_3:"This e-ticket is not transferable.",
        important_4:"For any changes or modifications please contact us at: reservations@playamia.com",
        important_5:"By purchasing this package you acknowledge to have read and agreed with the terms & conditions shown and published in Playa Mia's web site in the following link: https://www.playamia.com/terms-conditions",
        cancellation_policy:"CANCELLATION POLICY AND NO SHOW",
        cancellation_policy_1:"Cancellation of the tour may occur by the operator, due to acts of \"force majeure\", including weather conditions: Hurricanes, tropical storms, governmental restrictions, acts of terrorism, war, insurrections, strikes or other labor disturbance, labor disputes, and other unforeseeable or unavoidable external circumstances that make it impossible, extremely difficult or dangerous to carry out the service. And/or any other cause beyond the reasonable control of the party whose performance is affected, the cancellation of the service on the day of operation will result in the operator refunding 100%",
        cancellation_policy_2:"Any cancellation requires a minimum of 24 hours notice.",
        cancellation_policy_3:"Travelers who cancel a booking more than 24 hours in advance will receive a full refund.",
        cancellation_policy_4:"Any cancellation after 24 hours will be charged at 100%.",
        cancellation_policy_5:"if the client does not show up at the correct time and on the correct day we will charged 100 %.",
        beach_break_transfer_service:"For the Beach Break, Dance & salsa and Mexican Cuisine without Transfer Service:",
        beach_break_transfer_service_1:"Guests need to show up directly at Playa Mia's park entrance.",
        beach_break_transfer_service_2:"Kindly note that Playa Mia opens from 9am to 6pm LOCAL TIME",
        beach_break_transfer_service_3:"Address: Playa Mia Carretera Costera Sur Km 15 Zona Hotelera Sur, Cozumel.",
        for_guest_hotel:"FOR GUESTS STAYING IN A HOTEL",
        for_guest_hotel_1:"For guests staying in the Northern hotel zone or downtown of Cozumel, the meeting point is Playa Mia retail booth just in front of the SSA International Pier, at the Royal Village Shopping Mall.",
        for_guest_hotel_2:"For guests staying in the Southern hotel zone of Cozumel, transfer service does not apply, as Playa Mia is close to these hotels. In this case, the meeting point is Playa Mia location.",
        for_dance_salsa_beach:"For the Beach Break, Dance & salsa and Mexican Cuisine with Transfer Service",
        for_dance_salsa_beach_1:"The transfer departs every sharp hour from 9am to 12hrs from the meeting points located outside the cruise ship piers, and you can take the ride back from Playa Mia to the pier every sharp our from 12 to 17hrs.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service:"Cozumel Snorkel Tour: Coral Reefs, el Cielo & Beach Break with Transfer Service",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_1:"The meeting time is 10:30 am LOCAL TIME and departure time is at 11 am.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_2:"Minimum age to participate is 8 years old.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_3:"Maximum age to participate is 70 years old.",
        meeting_points_for_peach_break_with_transfer:"Meeting Points for Beach Break with Transfer, Cozumel Snorkel Tour: Coral Reef, El Cielo & Playa Mia",
        from_puerta_maya_pier:"From Puerta Maya Pier",
        from_puerta_maya_pier_1:"You will need to show up at our meeting point at Royal Village shopping center. In order to find it, please walk outside the cruise terminal, once outside, facing the street and with the sea on your back walk to the left and look for the Hard Rock Café at this shopping center.",
        from_internacional_pier:"From International Pier",
        from_internacional_pier_1:"Walk outside the cruise terminal, in front of the pier you will find the Royal Village shopping center. Cross the street by the pedestrian crossing to find the Playa Mia booth at the mall's main entrance, just in front of the Hard Rock Café.",
        from_punta_langosta_pier:"From Punta Langosta Pier",
        from_punta_langosta_pier_1:"The meeting point is located inside the cruise terminal, in front of the Duty free store.",
        phone_number:"PHONE NUMBER"
    },
    [namespaces.open_pay]:{
        credit_card:"Credit cards",
        debit_card:"Debit cards",
        owner_name:"Name of owner",
        number_card:"Card number",
        expiration_date:"Expiration date",
        month:"Month",
        year:"Year",
        security_code:"Security code",
        transaction:"Transactions made via:",
        your_payment:"Your payments are made securely with 256-bit encryption",
        pay:"Pay",
        three_digit:"3 digits",
        name_in_card:"As it appears on the card",
    },
    [namespaces.thank_you]:{
        thank:"Thank you",
        for_your_reservation:"for your reservation!",
        few_moments:"In a few moments you will receive in your mail:",
        ticket_folio:"a confirmation ticket with folio",
    },
    [namespaces.error_open_pay]:{
        folio:"Your folio is :",
        an_error:"an error has occurred while processing the payment, ",
        no_charge:"no charge has been made to your card",
    },
    [namespaces.modal_addon]:{
        addon_rentals:"ADD-ON Rentals",
        reservation_number:"Reservation number: ",
        name:"Name:",
        date_tour:"Date tour:",
        service:"Service:",
        adults:"Adults:",
        add:"Add",
        beds:"Beds:",
        water_m:"Wave Runners"
    },
    [namespaces.announcement]:{
        paragraph:"The Oasis Island Pool & Hydro Massage Tub will be undergoing maintenance until further notice. During this time, the Twin Twister Water Slides, Floating Park, Cantina Pool, and all other park activities will remain available. We appreciate your understanding."
    }

};

export const es = {
    [namespaces.header]: {
        lang: "ESP",
        shopping_cart:"Carrito de compras",
        add:"Agregar",
        acepp:"Aceptar",
        cancel:"Cancelar",
        question_1:" ¿Esta seguro que desea eliminar",
        question_2:"de su carrito de compras",
        attention:"Atención",
        something_wrong:"Algo ah salido mal...",
        date_choose:"La fecha que eligio no esta disponible por el momento, por favor elija una fecha diferente.",

    },
    [namespaces.steps]: {
        "Check your cart": "Verifica tu carrito",
        "Customer information":"Información del cliente",
        "Add-ons for your fun":"Complementos para tu diversión",
        "Payment process":"Proceso de pago",
        "Confirmation":"Confirmación",
    },
    [namespaces.check_your_cart]: {
        date_of_reservation: "Fecha de la Reservación",
        adults: "Adultos",
        children: "Niños",
        transportation: "Transportación",
        total: "Total",
    },
    [namespaces.client_information]: {
        please_enter_your_information_correctly: "Por favor ingrese su información correctamente",
        im_coming_in: "Estoy llegando en",
        hotel: "Hotel",
        cruise_line: "Crucero",
        select_hotel_cruise: "Selecciona Hotel / Crucero",
        choose: "Selecciona ",
        name: "Nombre",
        last_name: "Apellidos",
        email: "Email",
        phone_number: "Numero Telefónico",
        select_country:"Selecciona tu pais",
        state_province: "Estado/Provincia",
        city: "Ciudad",
        country: "Pais",
        confirm: "He leído y estoy de acuerdo con los términos y condiciones (carrito de compras).",

    },
    [namespaces.mistakes]:{
        select_an_arrival_method: "Seleccionar un método de llegada",
        Select_hotel_cruise_line: "Debes Seleccionar Hotel / Crucero",
        you_must_enter_a_name: "Debes ingresar un nombre",
        you_must_enter_your_last_name: "Debes ingresar tus apellidos",
        you_must_enter_a_valid_email: "Debes de ingresar un email valido",
        you_must_enter_a_phone_number: "Debes de ingresar un numero de telefono valido",
        you_must_enter_a_state_or_province: "Debes ingresar un estado ó provincia",
        you_must_choose_a_country: "Debes elegir un país",
        you_must_choose_a_city: "Debes ingresar una ciudad",
        checked_confirm: "Debes marcar en el circulo para proceder.",
    },
    [namespaces.add_ons]:{
        recommended_products_to_increase_your_fun:"Productos recomendados para acompañar tu diversión",
        adults:"Adultos",
        total:"Total",
        add:"Agregar",
        recommended_products_to_increase_your_fun_snorkel:"Mejora tu experiencia con alguna de nuestras actividades extra, disponibles para reservar al llegar al club"
    },
    [namespaces.payment_process]:{
        details_of_the_reservation:"Detalles de la Reservación",
        date_of_reservation:"Fecha de la Reservación",
        adults:"Adultos",
        children:"Niños (6-11)",
        transportation:"Transportación",
    },
    [namespaces.purchase_details]:{
        apply_code:"Aplicar código",
        enter_code:"Ingrese su código de descuento",
        purchase_details:"Detalles de la compra",
        subtotal:"Sub Total",
        total_discount:"Total de Descuento",
        total:"Total",
        pay_with:"Pagar con",
        payment_methods:"Métodos de Pago",
        continue:"Continuar",
        card:"Paypal",
        snorkel_1_january:"Por disposición establecida por el gobierno federal, informamos que a partir del 1 de enero de 2025 se aplicará un impuesto referente a la conservación del parque marino de $11 dólares el cual no está incluido en el costo y deberá ser cubierto por el cliente en efectivo el día del tour."
    },
    [namespaces.final_book]:{
        booking_number:"NÚMERO DE RESERVA",
        country_origin:"PAÍS DE ORIGEN",
        date_of_purchase:"FECHA DE COMPRA",
        city:"CIUDAD",
        package_price:"TOTAL DE LA COMPRA",
        name:"NOMBRE DEL VISITANTE",
        date_arrival:"DÍA DE VISITA",
        adult:"Adulto",
        child:"Menor",
        cruise_line:"CRUCERO/ HOTEL",
        date_of_arrival:"Día de visita",
        important_facts:"DATOS IMPORTANTES PARA TU DÍA EN PLAYA MIA",
        important_1:"Playa Mia está abierto de lunes a sábado, de 9 am a 6 pm HORA LOCAL",
        important_2:"Imprima este boleto electrónico y tráigalo, deberá mostrarlo en la entrada del parque.",
        important_3:"Este boleto electrónico no es transferible.",
        important_4:"Para cualquier cambio o modificación por favor contáctenos a: reservations@playamia.com",
        important_5:"Al comprar este paquete, usted reconoce haber leído y aceptado los términos y condiciones\n" +
            "que se muestran y publican en el sitio web de Playa Mia en el siguiente\n" +
            "enlace: https://www.playamia.com/terms-conditions",
        cancellation_policy:"POLÍTICA DE CANCELACIÓN Y NO SHOW",
        cancellation_policy_1:"La cancelación del tour puede ocurrir por parte del operador, debido a actos de ¿fuerza\n" +
            "mayor?, incluyendo condiciones climáticas: huracanes, tormentas tropicales, restricciones\n" +
            "gubernamentales, actos de terrorismo, guerra, insurrecciones, huelgas u otros disturbios\n" +
            "laborales, disputas laborales y otros imprevistos o circunstancias externas inevitables que\n" +
            "hagan imposible, extremadamente difícil o peligroso realizar el servicio. y/o cualquier otra\n" +
            "causa más allá del control razonable de la parte cuyo rendimiento se ve afectado, la\n" +
            "cancelación del servicio en el día de operación resultará en que el operador reembolse el\n" +
            "100%.",
        cancellation_policy_2:"Cualquier cancelación requiere un mínimo de 24 horas de antelación.",
        cancellation_policy_3:"Los viajeros que cancelen una reserva con más de 24 horas de antelación recibirán un reembolso completo.",
        cancellation_policy_4:"Cualquier cancelación después de 24 horas se cobrará el 100%.",
        cancellation_policy_5:"Si el cliente no se presenta a la hora y el día correctos se le cobrará el 100 %.",
        beach_break_transfer_service:"PARA EL BEACH BREAK, DANCE & SALSA Y COCINA MEXICANA SIN SERVICIO DE TRANSFER:",
        beach_break_transfer_service_1:"Los huéspedes deben presentarse directamente en la entrada del parque de Playa Mia. ",
        beach_break_transfer_service_2:"Tenga en cuenta que Playa Mia abre de 9 am a 6 pm HORA LOCAL",
        beach_break_transfer_service_3:" Dirección: Playa Mia Carretera Costera Sur Km 15 Zona Hotelera Sur, Cozumel",
        for_guest_hotel:"PARA HUÉSPEDES ALOJADOS EN HOTEL:",
        for_guest_hotel_1:"Para los huéspedes que se hospedan en la zona hotelera del norte o en el centro de Cozumel, el punto de encuentro es el puesto de venta minorista de Playa Mia, justo frente al muelle internacional SSA, en el centro comercial Royal Village.",
        for_guest_hotel_2:"Para los huéspedes que se hospeden en la zona hotelera Sur de Cozumel, no aplica el servicio de traslado, ya que Playa Mia se encuentra cerca de estos hoteles. En este caso, el punto de encuentro es la ubicación de Playa Mia.",
        for_dance_salsa_beach:"PARA EL BEACH BREAK, DANCE & SALSA Y COCINA MEXICANA CON SERVICIO DE TRANSFER:",
        for_dance_salsa_beach_1:"El traslado sale cada hora en punto de 9:00 a 12:00 desde los puntos de encuentro ubicados fuera de los muelles de cruceros, y puede tomar el viaje de regreso desde Playa Mia al muelle cada hora en punto de 12:00 a 17:00.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service:"Tour de Snorkel en Cozumel: Arrecifes de Coral, el Cielo & Beach Break con Servicio de Traslado",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_1:"La hora de encuentro es a las 10:30 am HORA LOCAL y la hora de salida es a las 11 am.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_2:"La edad mínima para participar es de 8 años.",
        cozumel_snorkel_tour_coral_reefs_el_cielo_service_3:" La edad máxima para participar es de 70 años.",
        meeting_points_for_peach_break_with_transfer:"PUNTOS DE ENCUENTRO PARA BEACH BREAK CON TRANSPORTACIÓN INCLUIDA COZUMEL SNORKEL TOUR: CORAL REEF, EL CIELO & PLAYA MIA",
        from_puerta_maya_pier:"Desde el Muelle Puerta Maya",
        from_puerta_maya_pier_1:"Deberá presentarse en nuestro punto de encuentro en el centro comercial Royal Village. Para encontrarlo, camine fuera de la terminal de cruceros, una vez afuera, de frente a la calle y con el mar de espaldas, camine hacia la izquierda y busque el Hard Rock Café en este centro comercial.",
        from_internacional_pier:"Desde el Muelle Internacional",
        from_internacional_pier_1:"Camina fuera de la terminal de cruceros, frente al muelle encontrarás el centro comercial Royal Village. Cruza la calle por el paso de peatones para encontrar el stand de Playa Mia en la entrada principal del centro comercial, justo en frente del Hard Rock Café.",
        from_punta_langosta_pier:"Desde Muelle Punta Langosta",
        from_punta_langosta_pier_1:"El punto de encuentro se encuentra dentro de la terminal de cruceros, frente a la tienda libre de impuestos",
        phone_number:"NÚMERO DE TELÉFONO"
    },
    [namespaces.open_pay]:{
        credit_card:"Tarjetas de crédito",
        debit_card:"Tarjetas de débito",
        owner_name:"Nombre del titular",
        number_card:"Número de tarjeta",
        expiration_date:"Fecha de expiración",
        month:"Mes",
        year:"Año",
        security_code:"Código de seguridad",
        transaction:"Transacciones realizadas vía:",
        your_payment:"Tus pagos se realizan de forma segura con encriptación de 256 bits",
        pay:"Pagar",
        three_digit:"3 dígitos",
        name_in_card:"Como aparece en la tarjeta",
    },
    [namespaces.thank_you]:{
        thank:"¡Gracias",
        for_your_reservation:"por su reserva!",
        few_moments:"En unos momentos recibira en su correo:",
        ticket_folio:"un ticket de confirmación con folio",
    },
    [namespaces.error_open_pay]:{
        folio:"Tu folio es:",
        an_error:"ha ocurrido un error al procesar el pago,",
        no_charge:"no se ha realizado ningún cargo en su tarjeta",
    },
    [namespaces.modal_addon]:{
        addon_rentals:"Atracciones Adicionales",
        reservation_number:"Número de reserva:",
        name:"Nombre:",
        date_tour:"Fecha del tour:",
        service:"Servicio:",
        adults:"Adultos:",
        add:"Agregar",
        beds:"Camas:",
        water_m:"Motos:"
    },
    [namespaces.announcement]:{
        paragraph:"La alberca Oasis e hidromasaje estarán en mantenimiento hasta nuevo aviso. Durante este tiempo, los toboganes, el parque flotante, la piscina cantina y todas las demás actividades del parque estarán disponibles para ti. Agradecemos tu comprensión."
    }



};
